import React from 'react';
import DateTime from "../../../../../utils/DateTime";
import TimePeriod from "../Formatters/TimePeriod";

const ReportTimeInterval = ({ start, end }) => {
    const ti = new TimePeriod(start, end);
    let output = ti.string;

    if (!end) {
        return <i>{output}</i>
    }

    if (!DateTime.areSameDays(start, end)) {
        const Date = ({ children }) => <span style={{color: 'grey'}}>{children}</span>

        output =
            <React.Fragment>
                <Date>{ti.startDate}</Date> {ti.startTime} - <Date>{ti.endDate}</Date> {ti.endTime}
            </React.Fragment>
    }
    return output;
}

export default ReportTimeInterval;