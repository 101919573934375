import { call, put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_AGGREGATED_TASKS_BEGIN,
    CHANGE_TASK_STATUS,
    fetchAggregatedTasksBegin,
    fetchAggregatedTasksSuccess,
    fetchAggregatedTasksFailure
} from '../actions/trackerTasks'

import { callApiGet, callApiPost } from '../../api/sagas/api';
import YMDDate from '../../../utils/YMDDate'

/**
 * Fetch tasks from server
 * @param action
 */
function* fetchAggregatedTasks(action) {
    try {
        let date = action.payload.date;
        let endpoint = '/tasks/aggregate';

        if(date) {
            endpoint += '?date=' + date;
        }

        const tasks = yield callApiGet(endpoint);

        if (tasks) {
            yield put(fetchAggregatedTasksSuccess(tasks));
        }
    } catch (e) {
        yield put(fetchAggregatedTasksFailure(e.message));
    }
}

/**
 * Reload tasks for today
 * Note: tracker page show tasks for today only
 */
function* reloadTasksForToday() {
    yield put(fetchAggregatedTasksBegin(YMDDate.today()));
}

/**
 * Change task status
 * @param action
 */
function* changeTaskStatus(action) {
    const task = action.payload.task;

    if (task.status) {
        yield callApiPost(`/tasks/${task.id}/stop`);
    } else {
        yield callApiPost(`/tasks/${task.id}/start`);
    }

    yield call(reloadTasksForToday);
}


/**
 * Root saga for tasks module
 */
function* trackerTasksSaga() {
    yield takeLatest(FETCH_AGGREGATED_TASKS_BEGIN, fetchAggregatedTasks);
    yield takeLatest(CHANGE_TASK_STATUS, changeTaskStatus);
}

export default trackerTasksSaga;