import { put, takeLatest, takeEvery, select, call } from 'redux-saga/effects'

import {
    FETCH_REPORT_LOGS_BEGIN,

    SET_REPORT_DATE,
    fetchReportLogsSuccess,
    fetchReportLogsFailure,
    fetchReportIntervalsSuccess,
    fetchReportIntervalsFailure, INSERT_LOGS, insertLogsFailure, insertLogsSuccess
} from '../actions/reports'

import { callApiGet, callApiPost } from '../../api/sagas/api';

import { fetchAnnualReport } from './annual-report';
import { FETCH_ANNUAL_REPORT_BEGIN } from '../actions/annual-report'

import { ALL_TASKS_STORE_KEY } from "../constants";


/**
 * Fetch logs from server
 * @param action
 */
function* fetchReportLogs(action) {
    try {
        const date = action.payload.date;
        let endpoint = `/reports/logs/day/${date}`;
        let logs = [];

        // TODO: move it to selectors folder, use selectors in the project
       // const annualReportItems = state => state.annualReports[action.payload.calendarStoreKey].logs;
        const annualReportItems = state => state.annualReports[ALL_TASKS_STORE_KEY].logs;

/*        const reports = yield select(annualReportItems);
        if (reports.length > 0) {
            const reportsMap = new Map(reports);
            if (reportsMap.has(JsDateString.fromYMDString(date))) {
                logs = yield callApiGet(endpoint);
            }
        } else {*/
            logs = yield callApiGet(endpoint);
      //  }

        if (logs) {
            yield put(fetchReportLogsSuccess(logs));
        }
    } catch (e) {
        yield put(fetchReportLogsFailure(e.message));
    }
}

/**
 * Fetch intervals from server
 * @param action
 */
function* fetchReportIntervals(action) {
    try {
        const date = action.payload.date;
        let endpoint = `/reports/time-intervals/day/${date}/aggregate?reported=false`;
        let intervals = [];

        // // TODO: move it to selectors folder, use selectors in the project
        // const annualReportItems = state => state.annualReports[action.payload.calendarStoreKey].intervals;
        //
        // const reports = yield select(annualReportItems);
        // if (reports.length > 0) {
        //     const reportsMap = new Map(reports);
        //     if (reportsMap.has(JsDateString.fromYMDString(date))) {
        //         intervals = yield callApiGet(endpoint);
        //     }
        // } else {
            intervals = yield callApiGet(endpoint);
        //}

        if (intervals) {
            yield put(fetchReportIntervalsSuccess(intervals));
        }
    } catch (e) {
        yield put(fetchReportIntervalsFailure(e.message));
    }
}

function* fetchReportItems(action) {
    yield call(fetchReportLogs, action);
    yield call(fetchReportIntervals, action);
}

function* insertLogs(action) {
    try {
        const endpoint = `/reports/logs/day/${action.payload.date}`;
        const logs = yield callApiPost(endpoint, action.payload.logs);

        if (logs) {
            yield put(insertLogsSuccess(logs));
        }
    } catch (e) {
        yield put(insertLogsFailure(e.message));
    }

}

/**
 * Root saga for reports module
 */
function* reportsSaga() {
    yield takeLatest(FETCH_REPORT_LOGS_BEGIN, fetchReportItems);
    yield takeLatest(SET_REPORT_DATE, fetchReportItems);

    yield takeEvery(FETCH_ANNUAL_REPORT_BEGIN, fetchAnnualReport);

    yield takeLatest(INSERT_LOGS, insertLogs);
}

export default reportsSaga;