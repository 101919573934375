import { connect } from 'react-redux'
import { fetchIntervalsBegin, saveIntervals } from '../actions/intervals'
import TimeIntervals from '../components/TimeIntervals';

const mapStateToProps = state => ({
    intervals: state.intervals.items,
    loading: state.intervals.loading,
    error: state.intervals.error
});

const mapDispatchToProps = dispatch => ({
    fetchIntervals: (date, diffMinutes) => dispatch(fetchIntervalsBegin(date, diffMinutes)),
    saveIntervals: (date, diffMinutes, task, intervals) => dispatch(saveIntervals(date, diffMinutes, task, intervals))
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeIntervals)