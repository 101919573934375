import React from "react";
import YMDDate from "../../../../utils/YMDDate"
import Year from "../../../../utils/Year"

import Chart from "react-google-charts";

import CalendarUI from "./CalendarUI"
import CalendarDataProxy from "./CalendarDataProxy"
import CalendarTooltip from "./CalendarTooltip"

const CalendarChart = props => {
    const visibleYear = Year.fromYMDString(props.date);
    const calDatesProxy = new CalendarDataProxy(
        visibleYear,
        CalendarTooltip
    );

    const onSelectDate = timestamp => {
         props.setReportDate(
            calDatesProxy.replaceYearBack(
                YMDDate.fromTimestamp(timestamp)
            )
        );
    }

    React.useEffect(() => {
        props.fetchAnnualReport(
                props.taskTitleStartedWith,
                props.taskIds,
                props.storeKey,
                visibleYear
        );
    }, []);

    const columns = [
        { type: 'date', id: 'Date' },
        { type: 'number', id: 'Spent Time' }
    ];

    const chartData = calDatesProxy.prepareData(
        [columns].concat(props.logs)
    );

    return (
        <Chart
            width={1000}
            height={200}
            chartType="Calendar"
            loader={<div>Loading Chart...</div>}
            options={{
                title: props.title,
                tooltip: {
                   isHtml: true
                },
                calendar: {
                    daysOfWeek: 'MTWTFSS'
                }
            }}
            data={chartData}
            chartEvents={[
            {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                    const ui = new CalendarUI(chartWrapper.getContainerId());
                    ui.removeTooltip();
                    ui.cleanManualSelectedDate();

                    const selection = chartWrapper.getChart().getSelection();
                    selection.length === 1 && onSelectDate(selection[0].date);
                },
            },
            {
                 eventName: 'ready',
                 callback: ({ chartWrapper }) => {
                      const ui = new CalendarUI(chartWrapper.getContainerId());

                      ui.removeDaysAfterToday(visibleYear);
                      ui.setYearLabel(visibleYear);
                      ui.configureScale();

                      ui.selectDateManually(props.date);
                 },
            }
            ]}
            />
        );
}

export default CalendarChart;