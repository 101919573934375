import $ from 'jquery';
import YMDDate from '../../../../utils/YMDDate'
import Year from '../../../../utils/Year'
import { isLeapYear } from './CalendarDateConverter'

class CalendarUI {
    constructor(containerString) {
        this.container = $(`#${containerString} svg`);

        this.childrenG0 = this.getChildrenG(0);
        this.childrenG1 = this.getChildrenG(1);
        this.childrenG2 = this.getChildrenG(2);
        this.childrenG4 = this.getChildrenG(4);

        this.manualSelectedAttr = {
            name: 'manually-selected',
            value: true
        };
    }

    getChildrenG(num) {
        return this.container.children('g')[num]
    }

    /**
     * clones the logic for clicking / selecting a day via mouse
     * doesn't work with charts which needs to display more than one year!
     */
    selectDateManually(ymdDateString) {
        const dayOfYear = Year.dayOfYear(ymdDateString);

        const daySvgRect = $(
            `rect:nth-of-type(${dayOfYear})`,
            $(this.childrenG1)
        );

        // hide the original rect
        // $(daySvgRect).css('display: none;');

        // add a cloned rect with a different stroke
        const markedDaySvgRect = $(daySvgRect).clone();

        $(markedDaySvgRect)
            .attr({
                'stroke': '#000000',
                'stroke-width': 2,
                'stroke-opacity': null,
                'fill-opacity': 1
            })
            .attr(this.manualSelectedAttr);

        $(this.childrenG4).append(markedDaySvgRect);
    }

    cleanManualSelectedDate() {
        $(this.childrenG4)
            .find(`[${this.manualSelectedAttr.name}=${this.manualSelectedAttr.value}]`)
            .remove();
    }

    setYearLabel(visibleYearInt) {
        $(this.childrenG0)
            .find(`:eq(15)`)
            .text(visibleYearInt);
    }

    configureScale() {
        let min = $(this.childrenG0).find(`:eq(1)`);
        let max = $(this.childrenG0).find(`:eq(2)`);

        const minVal = $(min).text();
        const maxVal = $(max).text();

        if (isNaN(minVal)) {
            $(min).remove();
            $(max).remove();

            $(this.childrenG2).find(':eq(0), :eq(1)').fadeOut();
            $(this.childrenG2).find(':eq(0), :eq(1)').remove();
        } else {
            $(min).text(Math.round(parseFloat(minVal)*100)/100);
            $(max).text(Math.round(parseFloat(maxVal)*100)/100);
        }
    }

    removeDaysAfterToday(visibleYearInt) {
        if (parseInt(visibleYearInt) !== Year.current()) {
            return;
        }

        let todayNum = Year.dayOfYear(YMDDate.today());
        const daysNum = isLeapYear(visibleYearInt) ? 366 : 365;

        $(this.childrenG1)
            .children()
            .slice(todayNum, daysNum)
            .remove();
    }

    removeTooltip() {
        $('.google-visualization-tooltip').remove();
    }
}

export default CalendarUI;