import React from 'react';
import { Input } from 'reactstrap';

const ReportComment = ({ defaultValue, onChange, disabled, placeholder }) => {
    return (disabled
            ? <Input type="textarea" placeholder="Stop the task to add a comment for this time slot" disabled />
            : <Input type="textarea" defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} />
    )
}

export default ReportComment;