import { connect } from 'react-redux'
import {toggleTrackerVisibility, fetchTasksBegin} from '../actions/tasks'
import TaskList from '../components/TaskList'
import TaskFilter from '../services/TaskFilter'

const mapStateToProps = state => ({
    tasks: TaskFilter.filter(state.tasks.items, state.visibilityFilter),
    loading: state.tasks.loading,
    error: state.tasks.error
});

const mapDispatchToProps = dispatch => ({
    toggleTrackerVisibility: task => dispatch(toggleTrackerVisibility(task)),
    fetchTasks: (date) => dispatch(fetchTasksBegin(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskList)