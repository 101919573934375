import { call, put, takeLatest } from 'redux-saga/effects'
import { AUTH_REQUEST, AUTH_SUCCESS, SIGN_OUT, authFailure, authSuccess } from '../actions/auth';
import Token from '../services/Token'
import { callPublicApiPost } from './api';
import { push } from 'connected-react-router';

function* authorize({ payload: { login, password } }) {
    try {
        const { token } = yield callPublicApiPost('/login', {
            username: login,
            password: password
        });
        yield call(Token.set, token);
        yield put(authSuccess(token));
    } catch (error) {
        yield put(authFailure(error.message));
        Token.remove();
    }
}

function* redirectToMainPage() {
    yield put(push('/'));
}

function* signOut() {
    yield call(Token.remove);
}

function* authSaga() {
    yield takeLatest(AUTH_REQUEST, authorize);
    yield takeLatest(AUTH_SUCCESS, redirectToMainPage);
    yield takeLatest(SIGN_OUT, signOut);
}

export default authSaga;