import { VisibilityFilters } from '../actions/trackerTasks'
import HoursString from "../../../utils/HoursString";

function TaskService(tasks) {

    this.filter = (filter) => {
        switch (filter) {
            case VisibilityFilters.SHOW_ALL:
                return tasks
            case VisibilityFilters.SHOW_STOPPED:
                return tasks.filter(t => !t.status)
            case VisibilityFilters.SHOW_IN_PROGRESS:
                return tasks.filter(t => t.status)
            default:
                throw new Error('Unknown filter: ' + filter)
        }
    }

    this.calculateTotalDuration = () => {
        if (tasks.length === 0) {
            return 0;
        }

        let totalDuration = 0;
        tasks.map(task => {
            totalDuration += task.total_duration.seconds;
        });

        return HoursString.fromSeconds(totalDuration).toString();
    };
}

export default TaskService;