import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap';

const Task = ({ onClick, showInTracker, text }) => (
    <tr className={
        'd-flex ' + (!showInTracker ? 'table-dark' : '')
    }>

        <td className="col-sm-2">
            {
                showInTracker ?
                    <Button size="sm" color="dark" onClick={onClick}>Hide</Button>
                    : <Button size="sm" color="light" onClick={onClick}>Show</Button>
            }
        </td>
        <td className="col-sm-10">{text}</td>
    </tr>
)

Task.propTypes = {
    onClick: PropTypes.func.isRequired,
    showInTracker: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
}

export default Task