import React from 'react'
import PropTypes from 'prop-types'
import Task from './Task'
import YMDDate from '../../../utils/YMDDate'
import {Table} from 'reactstrap';

const TaskList = ({error, loading, tasks, fetchTasks, toggleTrackerVisibility}) => {
    React.useEffect(() => {
        fetchTasks(YMDDate.today());
    }, []);

    if (error) {
        return <div>Error! {error}</div>;
    }

    /*        if (loading) {
                return <div>Loading...</div>;
            }*/

    return (
        <Table size="sm" responsive hover>
            <thead>
            <tr className="d-flex">
                <th className="col-sm-2">Visible In Tracker</th>
                <th className="col-sm-10">Task</th>
            </tr>
            </thead>
            <tbody>
            {tasks.map(task =>
                <Task
                    key={task.id}
                    text={task.title}
                    showInTracker={task.show_in_tracker}
                    onClick={() => toggleTrackerVisibility(task)}
                />
            )}
            </tbody>
        </Table>
    );
}

/*
 TaskList.propTypes = {
 tasks: PropTypes.arrayOf(PropTypes.shape({
 id: PropTypes.number.isRequired,
 completed: PropTypes.bool.isRequired,
 text: PropTypes.string.isRequired
 }).isRequired).isRequired,
 toggleTodo: PropTypes.func.isRequired
 }*/


export default TaskList;

