import React from 'react';
import {fetchAnnualReportBegin} from "../actions/annual-report";
import {connect} from 'react-redux';

import Year from '../../../utils/Year'
import {setReportDate} from "../actions/reports";
import {DEFAULT_STORE_KEY} from "../constants";
import YMDDate from "../../../utils/YMDDate";

const CalendarCharts = props => {
    const date = props.date || props.defaultDate || YMDDate.today();

    React.useEffect(() => {
        let yearFromReportDate = Year.fromYMDString(date);
        let year = null;

        React.Children.forEach(props.children, element => {
            if (!React.isValidElement(element)) return;

            year = (typeof element.props.year === 'undefined') ? yearFromReportDate : element.props.year; // make it possible to apply year in props in JSX, it's for Test component only
            if (element.props.forceFetch === true) { // it's for Test components only, because they haven't own fetch method
                props.fetchAnnualReport(element.props.taskIds, element.props.storeKey, year);
            }
        });
    }, [date]);

    let storeKey = DEFAULT_STORE_KEY;
    const childrenWithProps = React.Children.map(props.children, child => {
        storeKey = child.props.storeKey;

        // DEFAULT_STORE_KEY key is set during initialization, see reducer initialState
        if (typeof props.annualReports[storeKey] === 'undefined') {
            storeKey = DEFAULT_STORE_KEY;
        }

        return React.cloneElement(
            child,
            {
                ...props.annualReports[storeKey], // each child CalendarChart should access own props only
                key: Year.fromYMDString(date), // it's required to reload the calendar at reports page
                date: date,
                setReportDate: props.setReportDate,
                fetchAnnualReport: props.fetchAnnualReport,
            }
        )
    });

    return <div>{childrenWithProps}</div>

}

const mapStateToProps = state => ({
    annualReports: state.annualReports,
    date: state.reports.date,
    year: state.annualReports.year
});

const mapDispatchToProps = dispatch => ({
    fetchAnnualReport: (taskTitleStartedWith, taskIds, storeKey, year) => dispatch(
        fetchAnnualReportBegin(taskTitleStartedWith, taskIds, storeKey, year)
    ),
    setReportDate: date => dispatch(setReportDate(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCharts);