import { put } from 'redux-saga/effects'
import {callApiGet} from "../../api/sagas/api";
import { fetchAnnualReportSuccess, fetchAnnualReportFailure } from "../actions/annual-report";


/**
 * Fetch tasks from server
 * @param action
 */
export function* fetchAnnualReport(action) {
    const { year, storeKey, taskIds = [], taskTitleStartedWith : title = null } = action.payload;

    try {
        let endpoint = `/reports/logs/annual/${year}`;
        let qs = '';

        if(taskIds.length > 0) {
            const glue = '&taskIds[]=';
            qs += `${glue}${taskIds.join(glue)}`;
        }

        if (title !== null) {
            qs += `&taskTitleStartedWith=${title}`
        }

        if (qs.length > 0) {
            qs = qs.replace('&', '?');
            endpoint += qs;
        }

        const logs = yield callApiGet(endpoint);

        if (logs) {
            yield put(fetchAnnualReportSuccess(storeKey, logs));
        }
    } catch (e) {
        yield put(fetchAnnualReportFailure(storeKey, e.message));
    }
}