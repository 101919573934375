function Log(taskId, text, seconds) {
    if (typeof taskId !== 'string' || taskId.length === 0) {
        throw new TypeError('taskId is invalid');
    }
    if (typeof seconds !== 'number' || seconds < 0) {
        throw new TypeError('seconds parameter is invalid');
    }
    return {
        'task_id': taskId,
        'text': text,
        'duration': {
            'seconds': seconds
        }
    };
}

export default Log;