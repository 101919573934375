import {
    FETCH_REPORT_LOGS_BEGIN,
    FETCH_REPORT_LOGS_SUCCESS,
    FETCH_REPORT_LOGS_FAILURE,
    FETCH_REPORT_INTERVALS_BEGIN,
    FETCH_REPORT_INTERVALS_SUCCESS,
    FETCH_REPORT_INTERVALS_FAILURE,
    SET_REPORT_DATE,
    INSERT_LOGS,
    INSERT_LOGS_SUCCESS,
    INSERT_LOGS_FAILURE
} from '../actions/reports';

export const LOGS_STORE_KEY = 'logs';
export const INTERVALS_STORE_KEY = 'intervals';
export const NEW_LOGS_STORE_KEY = 'newLogs';

const initialState = {
    [LOGS_STORE_KEY]: {
        loading: false,
        error: null,
        items: []
    },
    [INTERVALS_STORE_KEY]: {
        loading: false,
        error: null,
        items: []
    },
    [NEW_LOGS_STORE_KEY]: {
        posting: false,
        success: false,
        error: null,
        payload: []
    },
    date: null
};

const reportsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_REPORT_LOGS_BEGIN:
            return {
                ...state,
                [LOGS_STORE_KEY]: {
                    ...state[LOGS_STORE_KEY],
                    loading: true,
                    error: null
                }
            };

        case FETCH_REPORT_LOGS_SUCCESS:
            return {
                ...state,
                [LOGS_STORE_KEY]: {
                    ...state[LOGS_STORE_KEY],
                    loading: false,
                    items: payload.items
                }
            };

        case FETCH_REPORT_LOGS_FAILURE:
            return {
                ...state,
                [LOGS_STORE_KEY]: {
                    ...state[LOGS_STORE_KEY],
                    loading: false,
                    error: payload.error,
                    items: []
                }
            };

        case FETCH_REPORT_INTERVALS_BEGIN:
            return {
                ...state,
                [INTERVALS_STORE_KEY]: {
                    ...state[INTERVALS_STORE_KEY],
                    loading: true,
                    error: null
                }
            };

        case FETCH_REPORT_INTERVALS_SUCCESS:
            return {
                ...state,
                [INTERVALS_STORE_KEY]: {
                    ...state[INTERVALS_STORE_KEY],
                    loading: false,
                    items: payload.items
                }
            };

        case FETCH_REPORT_INTERVALS_FAILURE:
            return {
                ...state,
                [INTERVALS_STORE_KEY]: {
                    ...state[INTERVALS_STORE_KEY],
                    loading: false,
                    error: payload.error,
                    items: []
                }
            };

        case SET_REPORT_DATE: {
            return {
                ...state,
                date: payload.date
            };
        }
        case INSERT_LOGS: {
            return {
                ...state,
                [NEW_LOGS_STORE_KEY]: {
                    ...state[NEW_LOGS_STORE_KEY],
                    posting: true,
                    payload: payload.logs,
                }
            };
        }
        case INSERT_LOGS_SUCCESS: {
            return {
                ...state,
                [NEW_LOGS_STORE_KEY]: {
                    ...state[NEW_LOGS_STORE_KEY],
                    posting: false,
                    success: true,
                    payload: []
                },
                [LOGS_STORE_KEY]: {
                    ...state[LOGS_STORE_KEY],
                    items: payload.logs
                },
                [INTERVALS_STORE_KEY]: {
                    ...state[INTERVALS_STORE_KEY],
                    items: []
                }
            };
        }
        case INSERT_LOGS_FAILURE: {
            return {
                ...state,
                [NEW_LOGS_STORE_KEY]: {
                    ...state[NEW_LOGS_STORE_KEY],
                    posting: false,
                    success: false,
                    error: payload.error
                }
            };
        }
        default:
            return state;
    }
};

export default reportsReducer;