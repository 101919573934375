import moment from 'moment-timezone-es6';
import 'moment-duration-format';

HoursString.FORMAT = 'HH:mm:ss';
HoursString.fromDate = (date) => new HoursString(moment(date).format(HoursString.FORMAT));
HoursString.fromMoment = (m) => new HoursString(m.format(HoursString.FORMAT));
HoursString.fromSeconds = (seconds) => {
    const hs = moment
        .duration(seconds, 'seconds')
        .format(HoursString.FORMAT, { trim: false });
    return new HoursString(hs);
}

/**
 * it's named as HoursString, because in a future it will have more hours than 24
  */
function HoursString(hoursString) {
    const hString = hoursString;

    const m = moment(hString, HoursString.FORMAT, true);
    const date = m.toDate();

    // it's a question should I do this
/*    if (!m.isValid()) {
        throw new Error(`Invalid hours string (${hString}) provided. Valid format: ${HoursString.FORMAT}`);
    }*/

    const format = (m) => m.format(HoursString.FORMAT);

    this.toString = function() {
        return format(m);
    }

    this.toMoment = function() {
        return m;
    }

    this.isValid = function() {
        return m.isValid();
    }

    /**
     * Rounds to the nearest minutes interval based on mathFunc
     *
     * @param minutes int
     * @param mathFunc - Math.round, Math.ceil, Match.floor
     * @returns {Date}
     */
    const roundToNearest = (minutes, mathFunc) => {
        if (typeof minutes !== 'number' || minutes <= 0) {
            throw new TypeError(`Invalid (${minutes}) minutes provided`);
        }

        const ms = 1000 * 60 * minutes;
        return new Date(mathFunc(date.getTime() / ms) * ms);
    };

    /**
     * Rounds to the nearest 15 minutes interval
     *
     * @returns {HoursString}
     */
    this.roundToNearestMinutes = (minutes) => HoursString.fromDate(roundToNearest(minutes, Math.round));

    /**
     * Rounds to the nearest 15 minutes interval up
     *
     * @returns {HoursString}
     */
    this.roundToNearestMinutesUp = (minutes) => HoursString.fromDate(roundToNearest(minutes, Math.ceil));

    /**
     * Rounds to the nearest N minutes interval down
     *
     * @returns {HoursString}
     */
    this.roundToNearestMinutesDown = (minutes) => HoursString.fromDate(roundToNearest(minutes, Math.floor));

    this.asMinutes = () => moment.duration(hString).asMinutes();

    this.addMinutes = (min) => {
        m.add({minutes: min})
        return HoursString.fromMoment(m);
    };

    this.subtractMinutes = (min) => {
        m.subtract({minutes: min});
        return HoursString.fromMoment(m);
    }

    this.setSecond = (seconds) => {
        m.set('second', seconds);
        return HoursString.fromMoment(m);
    }

    this.minute = () => m.minute();
}

export default HoursString