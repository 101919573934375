const CalendarTooltip = (dateObj, spentHours = null) => {
    let spentHoursStr = (spentHours === null)
        ? 'No spent time found'
        : `Spent Hours: ${Math.round(spentHours*100)/100}`;

    return `<div class="hint" style="padding:1px 5px 5px 5px;width:150px;font-size: 14px">
        ${dateObj.toDateString().slice(3,10)}<br />
        ${spentHoursStr}
    </div>`;
};

export default CalendarTooltip;