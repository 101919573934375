import React from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {connect} from "react-redux";

import {setReportDate} from "../actions/reports"
import {setAnnualReportYear} from "../actions/annual-report";

import YMDDate from "../../../utils/YMDDate"
import Year from "../../../utils/Year";

const DatePagination = props => {
    const {
        defaultDate,
        date,
        calendarStoreKey,
        setReportDate,
        setAnnualReportYear
    } = props;

    const visibleDate = date || defaultDate;

    const onPrevClick = () => changeReportDate(YMDDate.decrement(visibleDate));
    const onNextClick = () => !shouldDisableNextDay() && changeReportDate(YMDDate.increment(visibleDate))
    const changeReportDate = date => setReportDate(date) && setAnnualReportYear(calendarStoreKey, Year.fromYMDString(date));
    const shouldDisableNextDay = () => YMDDate.isMoreThanToday(visibleDate);

    return (
        <Pagination aria-label="Page navigation example" className="offset-5">
            <PaginationItem>
                <PaginationLink previous onClick={onPrevClick}/>
            </PaginationItem>

            <PaginationItem disabled>
                <PaginationLink>{visibleDate}</PaginationLink>
            </PaginationItem>

            <PaginationItem className={shouldDisableNextDay() ? 'disabled' : ''}>
                <PaginationLink next onClick={onNextClick}/>
            </PaginationItem>
        </Pagination>
    );
}

const mapStateToProps = state => ({
    date: state.reports.date,
});

const mapDispatchToProps = dispatch => ({
    setReportDate: date => dispatch(setReportDate(date)),
    setAnnualReportYear: (storeKey, year) => dispatch(setAnnualReportYear(storeKey, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePagination);