export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const SIGN_OUT = 'SIGN_OUT';

export const authRequest = (login, password) => ({
    type: AUTH_REQUEST,
    payload: { login, password }
});

export const authSuccess = (token) => ({ type: AUTH_SUCCESS, payload: token });
export const authFailure = (message) => ({ type: AUTH_FAILURE, payload: message });
export const tokenExpired = (message) => ({ type: TOKEN_EXPIRED, payload: message });
export const signOut = () => ({ type: SIGN_OUT });