export function isLeapYear(yr) {
    return (yr % 400) ? ((yr % 100) ? ((yr % 4) ? false : true) : false) : true;
}

// this function find a year with weekdays minus 1.
// For example, January the 1st in 2020 is on Wednesday,
// it will find the year when the 1st of January will be on Tuesday.
// this function takes in account leap years
export function getYearWithPrevWeekDay(currentYear) {
    let nearestLeapYear = null;
    let replacementYear = currentYear-1;

    // get the nearest leap year
    for (let i = currentYear; i > (currentYear - 4); i--) {
        if(isLeapYear(i)) {
            nearestLeapYear = i;
            break;
        }
    }

    let diff = currentYear - nearestLeapYear;

    if(diff === 0) {
        replacementYear -= 11;
    } else if(diff === 1) {
        replacementYear -= 5;
    }

    return replacementYear;
}