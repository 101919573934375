import React from 'react';

const PageTitle = (props) => {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
            <h1 className="h1">{props.children}</h1>
        </div>
    );
};

export default PageTitle;