import React from "react"
import PropTypes from 'prop-types'
import ReportLog from './ReportRows/ReportLog'
import ReportInterval from './ReportRows/ReportInterval'
import {Table, Button} from 'reactstrap';
import MapXY from "../../../utils/MapXY";

const ReportRows = props => {
    const {
        logsError,
        logsLoading,
        logsItems,
        intervalsError,
        intervalsLoading,
        intervalsItems,
        insertLogsError,
        insertLogsSuccess,
        insertLogsPosting
    } = props;

    if (logsError || intervalsError) {
        return <div>Error! {logsError} {intervalsError}</div>;
    }

    /*        if (logsLoading) {
                return <div>Loading...</div>;
            }*/

    if ((!logsLoading && logsItems.length === 0)
        && !intervalsLoading && intervalsItems.length === 0
    ) {
        return <div className="alert alert-warning">No spent time found. Please try another date</div>;
    }

    let logsMap = new MapXY();
    const addLog = (taskId, id, item) => logsMap.set(taskId, id, item);
    const getLog = (taskId, id) => logsMap.get(taskId, id);
    const removeLog = (taskId, id) => logsMap.delete(taskId, id);
    const clearLog = taskId => logsMap.clearX(taskId);

    const onAddLogsClick= () => {
        props.insertLogs(logsMap.values(), props.date);
    };

    return (
        <React.Fragment>
            {intervalsItems.length > 0 &&
            <div className="row offset-11 mb-1">
                <Button className="btn btn-primary btn-sm" onClick={onAddLogsClick}>Save</Button>
            </div>
            }
            <span>{insertLogsError}</span>
            <Table size="sm" responsive hover>
                <thead>
                <tr className="d-flex" style={{textAlign: "center"}}>
                    <th className="col-4">Task</th>
                    <th className="col-6">Comment</th>
                    <th className="col-2">hh:mm:ss</th>
                </tr>
                </thead>
                <tbody>
                    {logsItems.map((row, index) =>
                        <ReportLog
                            key={`log-${index}-${row.task.id}-${Date.now()}`}
                            taskTitle={row.task.title}
                            taskStatus={row.task.status}
                            text={row.text}
                            duration={row.duration}
                        />
                    )}
                    {intervalsItems.map((row, index) =>
                        <ReportInterval
                            key={`interval-${index}-${row.id}-${Date.now()}`}
                            taskId={row.id}
                            taskTitle={row.title}
                            taskStatus={row.status}
                            duration={row.total_duration}
                            subIntervals={row.time_intervals}
                            addLog={addLog}
                            removeLog={removeLog}
                            clearLog={clearLog}
                            getLog={getLog}
                        />
                    )}
                </tbody>
            </Table>
        </React.Fragment>
    );
}

ReportRows.propTypes = {
    date: PropTypes.string.isRequired,
    calendarStoreKey: PropTypes.string.isRequired,
    logsLoading: PropTypes.bool.isRequired,
    intervalsLoading: PropTypes.bool.isRequired
}

/* ReportRows.propTypes = {
     logs: PropTypes.arrayOf(PropTypes.shape({
     id: PropTypes.number.isRequired,
     completed: PropTypes.bool.isRequired,
     text: PropTypes.string.isRequired
     }).isRequired).isRequired,
     toggleTodo: PropTypes.func.isRequired
 }*/

export default ReportRows;

