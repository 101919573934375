import { combineReducers } from 'redux';
import visibilityFilter from '../modules/tasks/reducers/visibilityFilter'
import tasks from '../modules/tasks/reducers/tasks'
import trackerTasks from '../modules/tracker/reducers/trackerTasks'
import reports from '../modules/reports/reducers/reports'
import annualReports from '../modules/reports/reducers/annual-report'
import intervals from '../modules/intervals/reducers/intervals'

import { connectRouter } from 'connected-react-router'
import authReducer from '../modules/api/reducers/auth'

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    reports,
    annualReports,
    trackerTasks,
    visibilityFilter,
    tasks,
    intervals
});

export default rootReducer