import { put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_INTERVALS_BEGIN,
    fetchIntervalsBegin,
    fetchIntervalsSuccess,
    fetchIntervalsFailure,
    SAVE_INTERVALS,
    saveIntervals
} from '../actions/intervals'

import { callApiGet, callApiPost } from '../../api/sagas/api';
import YMDDate from '../../../utils/YMDDate';

/**
 * Fetch tasks from server
 * @param action
 */
function* fetchIntervals(action) {
    const { date = YMDDate.yesterday(), diffMinutes = 2 } = action.payload; 

    try {
        let endpoint = `/time-intervals/detect/${date}?diffMinutes=${diffMinutes}&save=false`;
       // TODO: remove this comment, it's for testing purpose
      //  let endpoint = `/time-intervals/detect/2021-09-28?diffMinutes=${diffMinutes}&save=false`;
        const items = yield callApiGet(endpoint);

        if (items) {
            yield put(fetchIntervalsSuccess(items));
        }
    } catch (e) {
        yield put(fetchIntervalsFailure(e.message));
    }
}

function* saveAndAttachIntervals(action) {
    const { date, diffMinutes, task, intervals } = action.payload;

    // TODO: check that 0 item exists, id is set
    const intervalId = intervals[0].id;

    try {
        const result = yield callApiPost('/time-intervals', intervals);
        // TODO: result is OK, but false returned, correct it. It because there is no body, see ApiClass, function post
       // if (result) {
            const endpoint = `/time-intervals/${intervalId}/attach/${task}`;
            yield callApiPost(endpoint, '');
    
            yield put(fetchIntervalsBegin(date, diffMinutes));
      //  }
    } catch (e) {
        console.log(e.message);
    }
}

/**
 * Root saga for tasks module
 */
function* intervalSaga() {
    yield takeLatest(FETCH_INTERVALS_BEGIN, fetchIntervals);
    yield takeLatest(SAVE_INTERVALS, saveAndAttachIntervals);
}

export default intervalSaga;