import DateTime from "../../../../../utils/DateTime";

const createObject = (string, startDate, startTime, endDate = null, endTime = null) => ({
    'string': string,
    'startDate': startDate,
    'startTime': startTime,
    'endDate': endDate,
    'endTime': endTime
});

function TimePeriod(start, end) {
    const startDate = DateTime.formatDate(start);
    const startTime = DateTime.formatTime(start);

    if (!end) {
        return createObject(
            `started @ ${startTime}`,
            null,
            startTime
        );
    }

    const endDate = DateTime.formatDate(end);
    const endTime = DateTime.formatTime(end);

    let output = createObject(
        `${startTime} - ${endTime}`,
        startDate,
        startTime
    );

    if (!DateTime.areSameDays(start, end)) {
        output = createObject(
            `${startDate} ${startTime} - ${endDate} ${endTime}`,
            startDate,
            startTime,
            endDate,
            endTime
        );
    }

    return output;
}

export default TimePeriod;