const leadByZero = number => number > 9 ? number : `0${number}`;
const asYMDString = (year, month, day) => `${year}-${leadByZero(month)}-${leadByZero(day)}`;

const Year = {
    current: () => new Date().getFullYear(),
    firstDayOf: yearInt => asYMDString(yearInt, 1, 1),
    firstDayOfCurrentYear: function() {
        return this.firstDayOf(this.current());
    },
    firstDayOfYearAfter: function(yearInt) {
        yearInt++;
        return this.firstDayOf(yearInt);
    },
    dayOfYear: ymdDateStr => {
        const d = new Date(ymdDateStr),
            yn = d.getFullYear(),
            mn = d.getMonth(),
            dn = d.getDate();
        const d1 = new Date(yn,0,1,12,0,0); // noon on Jan. 1
        const d2 = new Date(yn,mn,dn,12,0,0); // noon on input date
        const ddiff = Math.round((d2-d1)/864e5);
        return ddiff+1;
    },
    fromYMDString: ymdDateStr => ymdDateStr.slice(0, 4)
};

export default Year;