import { VisibilityFilters } from '../actions/tasks'

const getVisibleTasks = (tasks, filter) => {
    switch (filter) {
        case VisibilityFilters.SHOW_ALL:
            return tasks
        case VisibilityFilters.SHOW_STOPPED:
            return tasks.filter(t => !t.status)
        case VisibilityFilters.SHOW_IN_PROGRESS:
            return tasks.filter(t => t.status)
        default:
            throw new Error('Unknown filter: ' + filter)
    }
}

const TaskFilter = {
    filter: getVisibleTasks
}

export default TaskFilter;