import React from 'react';
import SidebarLayout from '../layouts/SidebarLayout'
import PageTitle from '../elements/PageTitle'
import CalendarReport from "../../modules/reports/components/CalendarReport";
import addressBar from 'addressbar';
import YMDDate from "../../utils/YMDDate";
import {connect} from 'react-redux';
import {fetchAnnualReportBegin} from "../../modules/reports/actions/annual-report";
import {setReportDate} from "../../modules/reports/actions/reports";

const updateAddressBar = date => addressBar.value = addressBar.value.replace(addressBar.pathname, `/reports/${date}`);

const ReportsPage = (props) => {
    const date = props.pathDate || props.date || YMDDate.today();

    React.useEffect(() => {
        props.setReportDate(date);
    }, []);

    React.useEffect(() => {
        props.date !== null && updateAddressBar(props.date);
    }, [props.date]);

    return <SidebarLayout>
        <PageTitle>Reports</PageTitle>
        {props.date !== null && <CalendarReport date={date}/>}
    </SidebarLayout>
}

const mapStateToProps = state => ({
    date: state.reports.date,
    pathDate: state.router.location.pathname.slice(9)
});

const mapDispatchToProps = dispatch => ({
    setReportDate: date => dispatch(setReportDate(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);