export const FETCH_AGGREGATED_TASKS_BEGIN   = 'FETCH_AGGREGATED_TASKS_BEGIN';
export const FETCH_AGGREGATED_TASKS_SUCCESS = 'FETCH_AGGREGATED_TASKS_SUCCESS';
export const FETCH_AGGREGATED_TASKS_FAILURE = 'FETCH_AGGREGATED_TASKS_FAILURE';

export const CHANGE_TASK_STATUS = 'CHANGE_TASK_STATUS';


let nextTodoId = 0
export const addTask = text => ({
    type: 'ADD_TASK',
    id: nextTodoId++,
    text
})

export const setVisibilityFilter = filter => ({ type: 'SET_VISIBILITY_FILTER', filter})

export const VisibilityFilters = {
    SHOW_ALL: 'SHOW_ALL',
    SHOW_STOPPED: 'SHOW_STOPPED',
    SHOW_IN_PROGRESS: 'SHOW_IN_PROGRESS'
}

export const fetchAggregatedTasksBegin = date => ({ type: FETCH_AGGREGATED_TASKS_BEGIN, payload: {date}});
export const fetchAggregatedTasksSuccess = tasks => ({ type: FETCH_AGGREGATED_TASKS_SUCCESS, payload: { tasks }});
export const fetchAggregatedTasksFailure = error => ({ type: FETCH_AGGREGATED_TASKS_FAILURE, payload: { error }});

export const changeStatus = task => ({ type: CHANGE_TASK_STATUS, payload: { task }});
