/**
 * Two dimensional map
 * Each item of the map is another map
 *
 * Example:
 * const testMap = new MapXY();
 * testMap.set('x', 'y', {'id': 'test'});
 * testMap.set('x', '1111111', {'id': '1111111'});
 * testMap.set('x', '222222', {'id': '12222222111111'});
 * testMap.set('x2', 'trewtww', {'id': 'wetwetdfhsd'});
 * testMap.clearX('x');
 *
 * console.log(testMap.values());
 */
function MapXY() {
    let map = new Map();

    const convertToString = (x, y) => [x.toString(), y.toString()];

    this.set = function (x, y, item) {
        [x, y] = convertToString(x, y);
        let mapY = map.get(x);
        mapY = mapY instanceof Map ? mapY : new Map();
        map.set(x, mapY.set(y, item));
    };

    this.get = function (x, y) {
        [x, y] = convertToString(x, y);
        let mapY = map.get(x);
        return mapY instanceof Map ? mapY.get(y) : undefined;
    };

    this.clearX = function (x) {
        map.set(x, new Map());
    };

    this.values = function () {
        let values = [];
        map.forEach(mapY => {
            mapY.forEach(item => values.push(item))
        });

        return values;
    };

    // returns array of arrays, see 'list entries matrix' test
    this.entries = function () {
        let entries = [];

        Array.from(map.keys()).forEach(key => {
            let mapX = map.get(key);
            Array.from(mapX.keys()).forEach(
                keyX => entries.push([key, keyX, mapX.get(keyX)])
            );
        });

        return entries;
    };

    this.delete = function(x, y) {
        [x, y] = convertToString(x, y);
        let mapY = map.get(x);
        return mapY instanceof Map ? mapY.delete(y) : false;
    };
}

export default MapXY;