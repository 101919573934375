import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ token, component: Component, ...rest }) => (

    <Route {...rest} render={props => ( token == null ?
            (
               <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
            ) :
            ( <Component {...props} /> )
        )
    } />
);

const mapStateToProps = (state) => ({
    token: state.auth.token
});

export default connect(mapStateToProps)(PrivateRoute);