const toYMDString = (dateObject) => dateObject.toISOString().slice(0,10);

const YMDDate = {
    validate: (ymdDateStr) => {
        const date = new Date(ymdDateStr);
        try {
            date.toISOString();
            return true;
        } catch(e) {
            return false;
        }
    },
    today: () => {
        const date = new Date();
        return toYMDString(date);
    },
    yesterday: function() {
        return this.decrement(this.today());
    },
    tomorrow: function() {
        return this.increment(this.today());
    },
    increment: ymdDateStr => {
        const date = new Date(ymdDateStr);
        date.setDate(date.getDate() + 1);
        return toYMDString(date);
    },
    decrement: ymdDateStr => {
        const date = new Date(ymdDateStr);
        date.setDate(date.getDate() - 1);
        return toYMDString(date);
    },
    isMoreThanToday: ymdDateStr => {
        const date = new Date(ymdDateStr);

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        return date > yesterday;
    },
    fromTimestamp: ts => {
        const date = new Date(ts);
        return toYMDString(date);
    }
};

export default YMDDate;