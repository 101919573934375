import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { authRequest } from '../../modules/api/actions/auth';

import '../../assets/css/signin.css';

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { login: '', password: '' };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(input, e) {
        this.setState({ [input]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        const { login, password } = this.state;
        this.props.dispatch(authRequest(login, password));
    }

    render() {
        const { error, token, token_error } = this.props;

        if (token) {
            return <Redirect to="/" />;
        }

        return (
        <form className="login-container">
                <div className="form-signin text-center">
                    <h1 className="h3 mb-3 font-weight-normal">TimeSaver</h1>

                    {token_error &&
                    <div className="alert alert-warning">Your session is expired. Please login</div>
                    }

                    <input
                        type='text'
                        placeholder='Login'
                        value={this.state.login}
                        onChange={this.onChange.bind(this, 'login')}
                        className='form-control'
                    />

                    <input
                        type='password'
                        placeholder='Password'
                        value={this.state.password}
                        onChange={this.onChange.bind(this, 'password')}
                        className='form-control'
                    />

                <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={this.onSubmit}>Sign In</button>

                <div className="signin-error">
                    {error &&
                        <div className="alert alert-danger">{ error }</div>
                    }
                </div>
            </div>
        </form>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    error: state.auth.error,
    token_error: state.auth.token_error
});

export default connect(mapStateToProps)(Login);