export const FETCH_INTERVALS_BEGIN   = 'FETCH_INTERVALS_BEGIN';
export const FETCH_INTERVALS_SUCCESS = 'FETCH_INTERVALS_SUCCESS';
export const FETCH_INTERVALS_FAILURE = 'FETCH_INTERVALS_FAILURE';
export const SAVE_INTERVALS = 'SAVE_INTERVALS';


export const fetchIntervalsBegin = (date, diffMinutes) => ({ type: FETCH_INTERVALS_BEGIN, payload: { date, diffMinutes }});
export const fetchIntervalsSuccess = intervals => ({ type: FETCH_INTERVALS_SUCCESS, payload: { intervals }});
export const fetchIntervalsFailure = error => ({ type: FETCH_INTERVALS_FAILURE, payload: { error }});

export const saveIntervals = (date, diffMinutes, task, intervals) => ({ type: SAVE_INTERVALS, payload: { date, diffMinutes, task, intervals }});