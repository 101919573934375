import { connect } from 'react-redux'
import ReportRows from '../components/ReportRows'
import {fetchReportLogsBegin, insertLogs} from '../actions/reports';
import {INTERVALS_STORE_KEY, LOGS_STORE_KEY, NEW_LOGS_STORE_KEY} from "../reducers/reports";

const mapStateToProps = state => ({
    logsItems: state.reports[LOGS_STORE_KEY].items,
    logsLoading: state.reports[LOGS_STORE_KEY].loading,
    logsError: state.reports[LOGS_STORE_KEY].error,

    intervalsItems: state.reports[INTERVALS_STORE_KEY].items,
    intervalsLoading: state.reports[INTERVALS_STORE_KEY].loading,
    intervalsError: state.reports[INTERVALS_STORE_KEY].error,

    insertLogsError: state.reports[NEW_LOGS_STORE_KEY].error,
    insertLogsSuccess: state.reports[NEW_LOGS_STORE_KEY].success,
    insertLogsPosting: state.reports[NEW_LOGS_STORE_KEY].posting,

    date: state.reports.date,
});

const mapDispatchToProps = dispatch => ({
    fetchReportLogsBegin: (calendarStoreKey, date) => dispatch(fetchReportLogsBegin(calendarStoreKey, date)),
    insertLogs: (logs, date) => dispatch(insertLogs(logs, date))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportRows)