export const FETCH_ANNUAL_REPORT_BEGIN   = 'FETCH_ANNUAL_REPORT_BEGIN';
export const FETCH_ANNUAL_REPORT_SUCCESS = 'FETCH_ANNUAL_REPORT_SUCCESS';
export const FETCH_ANNUAL_REPORT_FAILURE = 'FETCH_ANNUAL_REPORT_FAILURE';

export const SET_ANNUAL_REPORT_YEAR = 'SET_ANNUAL_REPORT_YEAR';

export const fetchAnnualReportBegin = (taskTitleStartedWith, taskIds, storeKey, year) => ({
    type: FETCH_ANNUAL_REPORT_BEGIN,
    payload: { taskTitleStartedWith, taskIds, storeKey, year }
});
export const fetchAnnualReportSuccess = (storeKey, logs) => ({ type: FETCH_ANNUAL_REPORT_SUCCESS, payload: { storeKey, logs }});
export const fetchAnnualReportFailure = (storeKey, error) => ({ type: FETCH_ANNUAL_REPORT_FAILURE, payload: { storeKey, error }});

export const setAnnualReportYear = (storeKey, year) => ({ type: SET_ANNUAL_REPORT_YEAR, payload: { storeKey, year } });