import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import MainPage from './pages/MainPage';
import Login from './pages/LoginPage';
import ReportsPage from './pages/ReportsPage';

import PrivateRoute from '../modules/api/components/PrivateRoute';
import DashboardPage from './pages/DashboardPage';
import TasksPage from './pages/TasksPage'
import IntervalsPage from './pages/IntervalsPage'

const App = props => {
    const { history } = props;

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute exact path="/" component={MainPage} />
                <PrivateRoute path="/reports/:date?" component={ReportsPage} />
                <PrivateRoute path="/dashboard" component={DashboardPage} />
                <PrivateRoute path="/tasks" component={TasksPage} />
                <PrivateRoute path="/intervals" component={IntervalsPage} />
            </Switch>
        </ConnectedRouter>
    );
};

export default App;