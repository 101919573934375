import React from 'react';
import { connect } from 'react-redux';

import { signOut } from '../../modules/api/actions/auth';


const SignOutLink = ({signOut}) => {
    return (
        <a className="nav-link sign-out-link" onClick={signOut}>Sign out</a>
    );
};

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(signOut())
});

export default connect(null, mapDispatchToProps)(SignOutLink);