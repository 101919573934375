import moment from 'moment-timezone-es6';

const momentTz = timeString => moment(timeString).tz("Atlantic/Azores")

const DateTime = {
    formatDate: timeString => momentTz(timeString).format('Y-MM-DD'),
    formatTime: timeString => momentTz(timeString).format('HH:mm'),
    areSameDays: (day1, day2) => moment(day1).isSame(day2, 'day')
};

export default DateTime;


