export const FETCH_REPORT_LOGS_BEGIN   = 'FETCH_REPORT_LOGS_BEGIN';
export const FETCH_REPORT_LOGS_SUCCESS = 'FETCH_REPORT_LOGS_SUCCESS';
export const FETCH_REPORT_LOGS_FAILURE = 'FETCH_REPORT_LOGS_FAILURE';

export const FETCH_REPORT_INTERVALS_BEGIN   = 'FETCH_REPORT_INTERVALS_BEGIN';
export const FETCH_REPORT_INTERVALS_SUCCESS = 'FETCH_REPORT_INTERVALS_SUCCESS';
export const FETCH_REPORT_INTERVALS_FAILURE = 'FETCH_REPORT_INTERVALS_FAILURE';

export const SET_REPORT_DATE = 'SET_REPORT_DATE';

export const INSERT_LOGS = 'INSERT_LOGS';
export const INSERT_LOGS_SUCCESS = 'INSERT_LOGS_SUCCESS';
export const INSERT_LOGS_FAILURE = 'INSERT_LOGS_FAILURE';

export const fetchReportLogsBegin = (calendarStoreKey, date) => ({ type: FETCH_REPORT_LOGS_BEGIN, payload: { calendarStoreKey, date }});
export const fetchReportLogsSuccess = items => ({ type: FETCH_REPORT_LOGS_SUCCESS, payload: { items }});
export const fetchReportLogsFailure = error => ({ type: FETCH_REPORT_LOGS_FAILURE, payload: { error }});

export const fetchReportIntervalsBegin = (calendarStoreKey, date) => ({ type: FETCH_REPORT_INTERVALS_BEGIN, payload: { calendarStoreKey, date }});
export const fetchReportIntervalsSuccess = items => ({ type: FETCH_REPORT_INTERVALS_SUCCESS, payload: { items }});
export const fetchReportIntervalsFailure = error => ({ type: FETCH_REPORT_INTERVALS_FAILURE, payload: { error }});


export const setReportDate = date => ({ type: SET_REPORT_DATE, payload: { date } });

export const insertLogs = (logs, date) => ({ type: INSERT_LOGS, payload: { logs, date }});
export const insertLogsSuccess = logs => ({ type: INSERT_LOGS_SUCCESS, payload: { logs }});
export const insertLogsFailure = error => ({ type: INSERT_LOGS_FAILURE, payload: { error }});

