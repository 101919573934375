import React from 'react';
import DashboardCharts from "./DashboardCharts";
import DashboardChartsProd from "./DashboardChartsProd";

import {API_URL} from "../../config";

const DashboardPage = () => {

    if (API_URL === 'http://localhost:8100/api') {
        return <DashboardCharts />
    }

    return <DashboardChartsProd />;
};

export default DashboardPage;