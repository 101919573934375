import { getYearWithPrevWeekDay } from './CalendarDateConverter'
import createPeriod from 'date-period'
import Year from '../../../../utils/Year'
import JsDateString from "../../../../utils/JsDateString";

// TODO: remade it with Proxy to have private fields and some methods
class CalendarDataProxy {
    constructor(visibleYearInt, tooltipFn) {
        // TODO: remade validation with https://validatejs.org/
        if (typeof visibleYearInt === 'undefined') {
            throw new Error(`Missed required 'visibleYearInt' input parameter in CalendarDatesProxy class`);
        }

        if (typeof tooltipFn === 'undefined') {
            throw new Error(`Missed required 'tooltipFn' input parameter in CalendarDatesProxy class`);
        }

        this.visibleYear = visibleYearInt;
        this.replacementYear = getYearWithPrevWeekDay(this.visibleYear);

        this.tooltipFn = tooltipFn;
    }

    getReplacementYear() {
        return this.replacementYear;
    }

    replaceYear(dateStr) {
        return dateStr.replace(
            this.visibleYear,
            this.replacementYear
        );
    }

    replaceYearBack(dateStr) {
        return dateStr.replace(
            this.replacementYear,
            this.visibleYear
        );
    }

    replaceYearInDates(datesFromServer) {
        return datesFromServer.map(item => {
                let replacedItem = this.replaceYear(item[0]);

                return [
                    replacedItem,
                    this.createDataRow(
                        replacedItem,
                        item[1],
                        eval(`new ${replacedItem}`)
                    )
                ]
            }
        );
    }

    createDataRow(dateEvalStr, spentTime, dateObj) {
        return [
            dateEvalStr,
            spentTime,
            this.tooltipFn(dateObj, spentTime)
        ];
    }

    fulfillEmptyDates(newDates) {
        // add dates with null value to dates array OR
        // create array of dates from the beginning of the year using Map
        let dateMapFromServer = new Map(newDates);

        let start = new Date(Year.firstDayOf(this.visibleYear)),
            duration = 'P1D',
            end = new Date(Year.firstDayOfYearAfter(this.visibleYear)); // not included in result

        let period = createPeriod({ start, duration, end });

        let chartDates = [];

        for(let dateObj of period) {
            let y = this.replacementYear;
            let m = dateObj.getMonth();
            let d = dateObj.getDate();

            let key = JsDateString.fromYMD(y, m, d);

            let value = (dateMapFromServer.has(key))
                ? dateMapFromServer.get(key)
                : this.createDataRow(key, null, dateObj);

            chartDates.push(value);
        }
        return chartDates;
    }

    prepareData(chartData) {
        let columns = chartData[0];
        chartData.shift();

        let newDates = this.replaceYearInDates(chartData);
        let columnValues = this.fulfillEmptyDates(newDates);

        columns.push(
            {
                'type': 'string',
                'role': 'tooltip',
                'p': {
                   'html': true
                }
            }
        );

        return [columns].concat(columnValues);
    }
}

export default CalendarDataProxy;