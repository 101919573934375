import { call, put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_TASKS_BEGIN,
    TOGGLE_TRACKER_VISIBILITY,
    fetchTasksBegin,
    fetchTasksSuccess,
    fetchTasksFailure
} from '../actions/tasks'

import { callApiGet, callApiPost } from '../../api/sagas/api';
import YMDDate from '../../../utils/YMDDate'

/**
 * Fetch tasks from server
 * @param action
 */
function* fetchTasks(action) {
    try {
        let endpoint = '/tasks';
        const tasks = yield callApiGet(endpoint);

        if (tasks) {
            yield put(fetchTasksSuccess(tasks));
        }
    } catch (e) {
        yield put(fetchTasksFailure(e.message));
    }
}

/**
 * Reload tasks for today
 * Note: tracker page show tasks for today only
 */
function* reloadTasksForToday() {
    yield put(fetchTasksBegin());
}


/**
 * Change task status
 * @param action
 */
function* toggleTrackerVisibility(action) {
    const task = action.payload.task;

    if (task.show_in_tracker) {
        yield callApiPost(`/tasks/${task.id}/hide`);
    } else {
        yield callApiPost(`/tasks/${task.id}/show`);
    }

    yield call(reloadTasksForToday);
}


/**
 * Root saga for tasks module
 */
function* tasksSaga() {
    yield takeLatest(FETCH_TASKS_BEGIN, fetchTasks);
    yield takeLatest(TOGGLE_TRACKER_VISIBILITY, toggleTrackerVisibility);
}

export default tasksSaga;