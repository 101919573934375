import React from 'react'
import PropTypes from 'prop-types'
import TrackerTask from './TrackerTask'
import YMDDate from '../../../utils/YMDDate'
import {Table} from 'reactstrap';

const TrackerTaskList = ({error, loading, trackerTasks, totalDuration, fetchTasks, changeStatus}) => {
    React.useEffect(() => {
        fetchTasks(YMDDate.today());
    }, []);

    if (error) {
        return <div>Error! {error}</div>;
    }

    /*        if (loading) {
                return <div>Loading...</div>;
            }*/

    return (
        <Table size="sm" responsive hover>
            <thead>
            <tr className="d-flex" style={{textAlign: "center"}}>
                <th className="col-7 col-lg-10 col-xl-10">Task</th>
                <th className="col-3 col-lg-1 col-xl-1">Spent TODAY</th>
                <th className="col-2 col-lg-1 col-xl-1">Action</th>
            </tr>
            </thead>
            <tbody>
            {trackerTasks.map(task =>
                <TrackerTask
                    key={task.id}
                    text={task.title}
                    status={task.status}
                    total_duration={task.total_duration}
                    onClick={() => changeStatus(task)}
                />
            )}
            <tr className="d-flex" style={{backgroundColor: '#fff8e4'}}>
                <td className="col-7 col-lg-10 col-xl-10" style={{textAlign: "end"}}>
                    <div style={{marginTop: "4px"}}>Total:</div>
                </td>
                <td className="col-3 col-lg-1 col-xl-1">
                    <div style={{marginTop: "4px", textAlign: "center"}}>
                        {totalDuration}
                    </div>
                </td>
                <td className="col-2 col-lg-1 col-xl-1"></td>
            </tr>
            </tbody>
        </Table>
    );
}

/*
 TaskList.propTypes = {
 tasks: PropTypes.arrayOf(PropTypes.shape({
 id: PropTypes.number.isRequired,
 completed: PropTypes.bool.isRequired,
 text: PropTypes.string.isRequired
 }).isRequired).isRequired,
 toggleTodo: PropTypes.func.isRequired
 }*/


export default TrackerTaskList;

