import {
    AUTH_SUCCESS,
    AUTH_FAILURE,
    TOKEN_EXPIRED,
    SIGN_OUT,
    AUTH_REQUEST
} from '../actions/auth';

const initialState = {
    token: localStorage.getItem('token'),
    token_error: null,
    error: null
};

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AUTH_SUCCESS: {
            return { ...state, token: payload, token_error: null, error: null };
        }
        case AUTH_FAILURE: {
            return { ...state, error: payload }
        }
        case TOKEN_EXPIRED: {
            return { ...state, token: null, token_error: payload }
        }
        case SIGN_OUT: {
            return { ...state, token: null }
        }
        case AUTH_REQUEST: {
            return { ...state, error: null }
        }
        default:
            return state;
    }
};

export default authReducer;