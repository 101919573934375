import React, { useState } from 'react'
import { Table, Container } from 'reactstrap'
import FileList from './FileList'
import DateTime from '../../../utils/DateTime'
import { TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Input, Label, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import YMDDate from '../../../utils/YMDDate';

const TimeIntervals = ({ error, loading, intervals, fetchIntervals, saveIntervals }) => {
    React.useEffect(() => {
        fetchIntervals()
    }, []);

    if (error) {
        return <div>Error! {error}</div>;
    }

    // tabs
    const [activeTab, setActiveTab] = useState(1);
    const toggle = (tab, interval) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        setInterval(interval);
    }

    const [date, setDate] = useState(YMDDate.yesterday());
    const [diffMinutes, setDiffMinutes] = useState(5);

    // TODO: remove hardcoded ID
    const [task, setTask] = useState('4266a0d5-2d5c-4116-9813-dd370dcdc2ac');
    const [interval, setInterval] = useState();


    const detect = () => {
        fetchIntervals(date, diffMinutes);
        setActiveTab(1, interval);
    };
    
    const onChangeDate = (e) => setDate(e.target.value);
    const onChangeDiffMinutes = (e) => setDiffMinutes(e.target.value);
    const onChangeTask = (e) => setTask(e.target.value);

    const attach = () => {
        let timeInterval = interval;

        if (interval === undefined) {
            timeInterval = intervals[0].id;
        } 
        const result = intervals.find(item => item.id == timeInterval)
        saveIntervals(date, diffMinutes, task, [result]);
    };
    /*     if (loading) {
            return <div>Loading...</div>;
        } */

    return (
        <React.Fragment>
            <Form>
                <FormGroup row>
                    <Col sm={2}>
                        <Label for="date">Date</Label>
                        <Input type="text" defaultValue={date} onChange={onChangeDate} name="date" id="date" placeholder="Date" />
                    </Col>
                    <Col sm={2}>
                        <Label for="diffMinutes">Diff In Minutes</Label>
                        <Input type="text" defaultValue={diffMinutes} onChange={onChangeDiffMinutes} name="diffMinutes" id="diffMinutes" placeholder="Minutes" />
                    </Col>
                    <Col sm={2}>
                        <Button onClick={detect}>Go</Button>
                    </Col>
                </FormGroup>
            </Form>
            {(intervals.length > 0) && 
                <Form>
                    <FormGroup row>
                        <Col sm={6}>
                            {/* TODO: get this list from the server */}
                            <Input type="select" name="select" id="task" onChange={onChangeTask}>
                                <option value="4266a0d5-2d5c-4116-9813-dd370dcdc2ac">Project Management</option>
                                <option value="7e68b7e7-337f-4c9b-8088-771bba879c9a">Prepare Time Reports</option>
                                <option value="6676ab2d-77a5-4bdf-8ea2-8afe236df46a">Documenting & Requirements Analysis</option>
                            </Input>
                        </Col>
                        <Col sm={6}>
                            <Button color="light" onClick={() => attach()}>Attach Chosen Interval</Button>
                        </Col>
                    </FormGroup>
                </Form>
            }
            <Nav tabs>
                {intervals.map((interval, index) =>
                    <NavItem key={`nav-link${index + 1}`}>
                        <NavLink
                            className={classnames({ active: activeTab === index + 1 })}
                            onClick={() => toggle(index + 1, interval.id)}
                        >
                            [{DateTime.formatTime(interval.start_time)}-{DateTime.formatTime(interval.end_time)}] <br /><i style={{ 'color': 'grey' }}>{interval.duration.hours_string}</i>
                        </NavLink>
                    </NavItem>
                )}
            </Nav>

            <TabContent activeTab={activeTab}>
                {intervals.map((interval, index) =>
                    <TabPane tabId={index + 1} key={`tab${index + 1}`}>
                        <FileList files={interval.snapshots} />
                    </TabPane>
                )}
            </TabContent>
        </React.Fragment>
    );
}

export default TimeIntervals;

