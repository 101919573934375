import React from 'react';
import AddTask from '../../modules/tasks/containers/AddTask'
import TaskListContainer from '../../modules/tasks/containers/TaskListContainer'
import Filters from '../../modules/tasks/components/Filters'

import SidebarLayout from '../layouts/SidebarLayout'
import PageTitle from '../elements/PageTitle'

const TasksPage = () => {
    return (
        <SidebarLayout>
            <PageTitle>Tasks</PageTitle>
            {/* <AddTask />*/}

           {/* <Filters />*/}
            <TaskListContainer />
        </SidebarLayout>
    );
};

export default TasksPage;