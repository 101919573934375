import React from 'react';
import ReportTimeInterval from "./Elements/ReportTimeInterval";
import ReportDuration from "./Elements/ReportDuration";
import ReportComment from "./Elements/ReportComment";
import EditableDuration from "./Elements/EditableDuration";

const ReportSubInterval = ({text, startTime, endTime, duration, onReportCommentChange, onTimeChange}) => {
    const [comment, setComment] = React.useState(text);
    const onSubIntervalTimeChange = (hoursString) => onTimeChange(startTime, hoursString);

    React.useEffect(() => {
        if (endTime) {
            onReportCommentChange(startTime, comment, duration.seconds);
        }
    }, [comment]);

    return (
        <tr className="d-flex" style={{backgroundColor: '#fff8e4'}}>
            <td className="col-4" align="right">
                <ReportTimeInterval start={startTime} end={endTime}/>
            </td>
            <td className="col-6">
                <ReportComment
                    defaultValue={comment}
                    onChange={({target}) => setComment(target.value)}
                    disabled={!endTime}
                />
            </td>
            <td className="col-2">
                <ReportDuration>
                    <EditableDuration
                        hoursString={duration.hours_string}
                        onChange={onSubIntervalTimeChange}
                    />
                </ReportDuration>
            </td>
        </tr>
    );
}

export default ReportSubInterval;