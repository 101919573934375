import React, {useState, useRef} from 'react'
import {Input, Button, InputGroup, InputGroupAddon} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPlus, faMinus, faPen} from '@fortawesome/free-solid-svg-icons';
import HoursString from "../../../../../utils/HoursString";

const EditableDuration = ({hoursString, onChange}) => {
    const [editMode, setEditMode] = useState(false);
    const [hString, setHString] = useState(hoursString);
    const [invalidHoursString, setInvalidHoursString] = useState(false);
    const hoursInput = useRef();

    const toggleEditMode = () => setEditMode(!editMode);

    const getHS = (hoursString) => new HoursString(hoursString);

    // hours more than 24 hours will be invalid!
    const validateHS = (hoursString) => getHS(hoursString).isValid();

    const roundTo15Minutes = (hoursString) => {
        let hs = getHS(hoursString);
        if (!hs.isValid()) {
            setInvalidHoursString(true);
            return false;
        }

        if (hoursString === '23:45:00') {
            return hoursString;
        }

        // if round up required
        if (hs.minute() % 15 > 0) {
            // round up
            hs = hs.roundToNearestMinutesUp(15);
        } else {
            // increase by 15 minutes
            hs = hs.setSecond(0).addMinutes(15);
        }

        return hs.toString();
    }

    const decreaseBy15Minutes = (hoursString) => {
        let hs = getHS(hoursString);
        if (!hs.isValid()) {
            setInvalidHoursString(true);
            return false;
        }

        if (hs.asMinutes() <= 15) {
            return hoursString;
        }

        // if round down required
        if (hs.minute() % 15 > 0) {
            // round down
            hs = hs.roundToNearestMinutesDown(15);
        } else {
            // decrease by 15 minutes
            hs = hs.setSecond(0).subtractMinutes(15);
        }

        return hs.toString();
    }

    const onPlusClick = () => {
        const oldValue = editMode ? hoursInput.current.value : hString;
        const newValue = roundTo15Minutes(oldValue)
        newValue && setHString(newValue);
    }

    const onMinusClick = () => {
        const oldValue = editMode ? hoursInput.current.value : hString;
        const newValue = decreaseBy15Minutes(oldValue)
        newValue && setHString(newValue);
    }

    const onChangeTime = (e) => setHString(e.target.value);
    const onFocus = () => setInvalidHoursString(false);

    const onSave = () => {
        if (!validateHS(hoursInput.current.value)) {
            setInvalidHoursString(true);
            return false;
        }
        toggleEditMode();
    }

    React.useEffect(() => {
        validateHS(hString) && onChange(hString);
    }, [hString]);


    // view logic -----------------------------
    let inputControl = <InputGroupAddon addonType="append">
        <div style={{marginTop: '3px', cursor: 'pointer'}} onClick={toggleEditMode}>
            {hString}
        </div>
    </InputGroupAddon>;

    let saveControl = '';

    if (editMode) {
        inputControl = <Input
            invalid={invalidHoursString}
            type="text"
            value={hString}
            onChange={onChangeTime}
            onFocus={onFocus}
            innerRef={hoursInput}
        />;

        saveControl = <InputGroupAddon addonType="append">
            <Button color="link" size="sm" disabled={invalidHoursString} onClick={onSave}>
                <FontAwesomeIcon icon={faCheck} style={{cursor: 'pointer'}} />
            </Button>
        </InputGroupAddon>;
    }

    return (
        <React.Fragment>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <Button color="link" size="sm" disabled={invalidHoursString} onClick={onMinusClick}>
                        <FontAwesomeIcon icon={faMinus} style={{cursor: 'pointer'}} />
                    </Button>
                </InputGroupAddon>

                {inputControl}

                <InputGroupAddon addonType="append">
                    <Button color="link" size="sm" disabled={invalidHoursString} onClick={onPlusClick}>
                        <FontAwesomeIcon icon={faPlus} style={{cursor: 'pointer'}} />
                    </Button>
                </InputGroupAddon>

                {saveControl}

            </InputGroup>
        </React.Fragment>
    );
}

export default EditableDuration