import React from 'react'
import { Table, Col, Row, Container } from 'reactstrap'
import File from './File'

const FileList = ({files}) => {


    /*        if (loading) {
                return <div>Loading...</div>;
            }*/

    return (
        <Container>
        <Row>
        {files.map(file =>
            <File
                key={file.id}
                id={file.id}
                path={file.source}
                capturedAt={file.capturedAt}
            />
            )}
        </Row>
      </Container>
    );
}

export default FileList;

