import React from 'react';
import DatePagination from "./DatePagination";
import CalendarCharts from "../containers/CalendarCharts";
import CalendarChart from "./CalendarChart/CalendarChart";
import ReportRowsContainer from "../containers/ReportRowsContainer";

import YMDDate from "../../../utils/YMDDate";
import { ALL_TASKS_STORE_KEY } from "../constants";

const CalendarReport = (props) => {
    const date = (props.date === null) || !YMDDate.validate(props.date) ? YMDDate.today() : props.date;

    return <React.Fragment>
        <CalendarCharts defaultDate={props.date}>
            <CalendarChart storeKey={ALL_TASKS_STORE_KEY} />
        </CalendarCharts>

        <DatePagination defaultDate={props.date} calendarStoreKey={ALL_TASKS_STORE_KEY} />
        <ReportRowsContainer calendarStoreKey={ALL_TASKS_STORE_KEY}  />
    </React.Fragment>;
};

export default CalendarReport;