import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';

import App from './components/App';
import rootSaga from './sagas/root';
import createRootReducer from './reducers/root';

import { composeWithDevTools } from 'redux-devtools-extension';

// create middlewares
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const middleware = applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware
);

// create store
const store = createStore(createRootReducer(history), composeEnhancers(middleware));

// run saga middleware
sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <App history={history} />
    </Provider>,
    document.getElementById("root")
);