import TokenExpiredError from '../errors/TokenExpiredError';

export default class PrivateApiClass {
    constructor(apiUrl, token) {
        this.apiUrl = apiUrl;
        this.token = token;
        this.headers = { 'Content-Type': 'application/json' };

        if (this.token) {
            this.headers['Authorization'] = `Bearer ${this.token}`;
        }
    }

    formUrl(url) {
        return `${this.apiUrl}${url}`;
    }

    get(url) {
        return fetch(this.formUrl(url), {
                headers: this.headers
            })
            .then(res => res.json())
            .then(this.handleErrors)
            .then(json => {
                return json;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    post(url, body) {
        return fetch(this.formUrl(url), {
                headers: this.headers,
                method: 'post',
                body: JSON.stringify(body)
            })
            .then(res => res.json())
            .then(this.handleErrors)
            .then(json => {
                return json;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    handleErrors(json) {
        const e = json.error;
        if (e) {
            if (e.code === 401 && e.message === 'Expired JWT Token') {
                throw new TokenExpiredError(e.message);
            }

            throw new Error(e.message);
        }
        return json;
    }
}