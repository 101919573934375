import {
    FETCH_ANNUAL_REPORT_BEGIN,
    FETCH_ANNUAL_REPORT_SUCCESS,
    FETCH_ANNUAL_REPORT_FAILURE,
    SET_ANNUAL_REPORT_YEAR
} from '../actions/annual-report';
import { DEFAULT_STORE_KEY } from '../constants';

import Year from '../../../utils/Year'

const calendarInitialState = {
    logs: [],
    loading: false,
    error: null,
    year: Year.current()
};

const initialState = {
    [DEFAULT_STORE_KEY]: calendarInitialState
};


const annualReportReducer = (state = initialState, { type, payload }) => {

     switch (type) {
         case FETCH_ANNUAL_REPORT_BEGIN:
            return {
                ...state,
                [payload.storeKey]: {
                    ...state[payload.storeKey],
                    logs: [],
                    loading: true,
                    error: null,
                    year: Year.current()
                }
            };

        case FETCH_ANNUAL_REPORT_SUCCESS:
            return {
                ...state,
                [payload.storeKey]: {
                    ...state[payload.storeKey],
                    loading: false,
                    logs: payload.logs
                }
            };

        case FETCH_ANNUAL_REPORT_FAILURE:
            return {
                ...state,
                [payload.storeKey]: {
                    ...state[payload.storeKey],
                    loading: false,
                    error: payload.error,
                    logs: []
                }
            };
        case SET_ANNUAL_REPORT_YEAR:
            return {
                ...state,
                [payload.storeKey]: {
                    ...state[payload.storeKey],
                    year: payload.year
                }
            };
        default:
            return state;
    }
};

export default annualReportReducer;