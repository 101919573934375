import React from 'react';
import { NavLink } from 'react-router-dom';

import '../../assets/css/dashboard.css';
import '../../assets/css/reports.css';

import SignOutLink from '../elements/SignOutLink';

const SidebarLayout = (props) => {
    return (
        <React.Fragment>
            <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
                <span className="navbar-brand col-sm-3 col-md-2 mr-0">TimeSaver</span>
               <ul className="navbar-nav px-3">
                    <li className="nav-item text-nowrap">
                        <SignOutLink />
                    </li>
                </ul>
            </nav>

            <div className="container-fluid">
                <div className="row">
                    <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                        <div className="sidebar-sticky">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" exact to="/">Tracker</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/reports">Reports</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/dashboard">Dashboard</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/tasks">Tasks</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/intervals">Detected Time Intervals</NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                        {props.children}
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SidebarLayout;