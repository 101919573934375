export const FETCH_TASKS_BEGIN   = 'FETCH_TASKS_BEGIN';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

export const TOGGLE_TRACKER_VISIBILITY = 'TOGGLE_TRACKER_VISIBILITY';

let nextTodoId = 0
export const addTask = text => ({
    type: 'ADD_TASK',
    id: nextTodoId++,
    text
})

export const setVisibilityFilter = filter => ({ type: 'SET_VISIBILITY_FILTER', filter})

export const VisibilityFilters = {
    SHOW_ALL: 'SHOW_ALL',
    SHOW_STOPPED: 'SHOW_STOPPED',
    SHOW_IN_PROGRESS: 'SHOW_IN_PROGRESS'
}

export const fetchTasksBegin = () => ({ type: FETCH_TASKS_BEGIN, payload: {}});
export const fetchTasksSuccess = tasks => ({ type: FETCH_TASKS_SUCCESS, payload: { tasks }});
export const fetchTasksFailure = error => ({ type: FETCH_TASKS_FAILURE, payload: { error }});



export const toggleTrackerVisibility = task => ({ type: TOGGLE_TRACKER_VISIBILITY, payload: { task }});