import React from 'react';

const ReportTaskTitle = ({ title, onClick, hasGroups }) => {
    if (!hasGroups) {
        return <span style={{marginLeft: '21px'}}>{title}</span>
    }
    return <span style={{marginLeft: '5px', cursor: 'pointer'}} onClick={onClick}>
            {title}
    </span>
};

export default ReportTaskTitle;