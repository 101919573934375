import { call, put } from 'redux-saga/effects'
import { create } from '../../../sagas/effects/create'
import { API_URL } from '../../../config';
import ApiClass from '../services/ApiClass';
import TokenExpiredError from '../errors/TokenExpiredError';
import Token from '../services/Token'
import { tokenExpired } from '../actions/auth'

// --------------------- public api --------------------------------
function* getPublicApiInstance() {
    return yield create(ApiClass, API_URL);
}

function* callPublicApi(method, url, body) {
    const api = yield call(getPublicApiInstance);
    return yield call([api, method], url, body);
}

export function* callPublicApiPost(url, body) {
    return yield callPublicApi('post', url, body);
}

// --------------------- private api -------------------------------
function* getPrivateApiInstance() {
    const token = yield call(Token.get);
    return yield create(ApiClass, API_URL, token);
}

function* callApi(method, url, body) {
    const api = yield call(getPrivateApiInstance);
    try{
        return yield call([api, method], url, body);
    } catch(e) {
        if (e instanceof TokenExpiredError) {
            yield put(tokenExpired(e.message));
        }
        throw Error(e.message);
    }
}

export function* callApiGet(url) {
    return yield callApi('get', url);
}

export function* callApiPost(url, body) {
    return yield callApi('post', url, body);
}
