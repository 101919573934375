import { fork } from 'redux-saga/effects'
import authSaga from '../modules/api/sagas/auth';
import tasksSaga from '../modules/tasks/sagas/tasks';
import trackerTasksSaga from '../modules/tracker/sagas/trackerTasks';
import reportsSaga from '../modules/reports/sagas/reports';
import intervalSaga from '../modules/intervals/sagas/intervals';

function* rootSaga() {
    yield fork(authSaga);
    yield fork(trackerTasksSaga);
    yield fork(tasksSaga);
    yield fork(reportsSaga);
    yield fork(intervalSaga);
}

export default rootSaga;