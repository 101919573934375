import React from 'react'
import PropTypes from 'prop-types'
import YMDDate from '../../../../utils/YMDDate'
import ReportTaskTitle from "./Elements/ReportTaskTitle";

const isInProgress = (status, date) => status && YMDDate.today() == date;

const ReportLog = ({ taskTitle, taskStatus, text, duration, date }) => (
/*    <tr className={
        'd-flex ' + (isInProgress(taskStatus, date) ? 'table-warning' : '')
    }>*/
    <tr className="d-flex">
        <td className="col-4">
            <ReportTaskTitle title={taskTitle} />
        </td>
        <td className="col-6"><div className="comment-area">{text}</div></td>
        <td className="col-2">{duration.hours_string}</td>
       {/* <td className="col-1">{isInProgress(taskStatus, date) ? 'WIP' : ''}</td>*/}
    </tr>
);

ReportLog.propTypes = {
    taskStatus: PropTypes.number.isRequired
};

export default ReportLog