import React from 'react';
import SidebarLayout from '../layouts/SidebarLayout'
import PageTitle from '../elements/PageTitle'

import CalendarCharts from '../../modules/reports/containers/CalendarCharts'
import CalendarChart from '../../modules/reports/components/CalendarChart/CalendarChart'
import {ALL_TASKS_STORE_KEY} from "../../modules/reports/constants";

const DashboardChartsProd = () => {
    return (
        <SidebarLayout>
            <PageTitle>Dashboard</PageTitle>
            <CalendarCharts>
                <CalendarChart title="All Tasks" storeKey={ALL_TASKS_STORE_KEY} />
                <CalendarChart
                    title="Фортепиано"
                    taskTitleStartedWith="Фортепиано"
                    storeKey="piano"
                />
                <CalendarChart
                    title="Гитара"
                    taskTitleStartedWith="Гитара"
                    storeKey="guitar"
                />
                <CalendarChart
                    title="Pattern B"
                    taskTitleStartedWith="Pattern"
                    storeKey="patternB"
                />
            </CalendarCharts>
        </SidebarLayout>
    );
};

export default DashboardChartsProd;