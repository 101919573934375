import React from 'react';
import PropTypes from 'prop-types';

import ReportSubInterval from "./ReportSubInterval";
import ReportDuration from "./Elements/ReportDuration";
import ReportComment from "./Elements/ReportComment";
import ReportTaskTitle from "./Elements/ReportTaskTitle";
import GroupControl from "./Elements/GroupControl"
import TimePeriod from "./Formatters/TimePeriod";
import Log from "./Formatters/Log";
import EditableDuration from "./Elements/EditableDuration";
import moment from 'moment-timezone-es6';

// TODO: make dashboard calendar chart to show not fully reported days or days without reports at all

const ReportInterval = props => {
    let {
        taskId,
        taskTitle,
        taskStatus,
        text,
        duration,
        subIntervals,
        addLog,
        removeLog,
        clearLog,
        getLog
    } = props;

    const [isOpened, setIsOpened] = React.useState(false);
    const [logText, setLogText] = React.useState('');
    const [logs, setLogs] = React.useState(new Map());

    const hasSubIntervals = subIntervals.length > 1;
    const onClick = () => setIsOpened(!isOpened);

    // find null end time in any sub interval, if at least one found then disable text area
    const allIntervalsClosed = subIntervals.every(item => (item.end_time !== null));

    React.useEffect(() => {
        if (!isOpened && allIntervalsClosed) {
            const log = new Log(
                taskId,
                logText,
                duration.seconds
            );
            clearLog(taskId);
            addLog(taskId, taskId, log);
        }
    }, [logText, isOpened]);

    const onTimeChangeUniversal = (id, hoursString) => {
        const log = getLog(taskId, id);

        if (log) {
            log.duration.seconds = moment.duration(hoursString).asSeconds();
            addLog(taskId, id, log);
        }
    }

    const onIntervalTimeChange = (hoursString) => onTimeChangeUniversal(taskId, hoursString);

    const onSubIntervalCommentChange = (startTime, text, seconds) => {
        const log = new Log(taskId, text, seconds);
        removeLog(taskId, taskId);
        addLog(taskId, startTime, log);

        setLogs(logs.set(startTime, log));
    }

    const getSubIntervalComment = startTime => {
        const item = logs.get(startTime);
        return (item instanceof Object) ? item.text : '';
    }

    const timePeriod = new TimePeriod(
        subIntervals[0].start_time,
        subIntervals[0].end_time
    );
    const commentPlaceholder = hasSubIntervals ? '' : timePeriod.string;

    return (
        <React.Fragment>
            <tr className="d-flex table-warning">
                <td className="col-4">
                    {hasSubIntervals && <GroupControl grouped={isOpened} onClick={onClick} />}
                    <ReportTaskTitle
                        title={taskTitle}
                        hasGroups={hasSubIntervals}
                        onClick={onClick}
                    />
                </td>
                <td className="col-6">
                    {!isOpened &&
                        <ReportComment
                            defaultValue={logText}
                            onChange={({ target }) => setLogText(target.value)}
                            placeholder={commentPlaceholder}
                            disabled={!allIntervalsClosed}
                        />
                    }
                </td>
                <td className="col-2">
                    {!isOpened &&
                        <ReportDuration forceWip={!allIntervalsClosed}>
                            <EditableDuration
                                hoursString={duration.hours_string}
                                onChange={onIntervalTimeChange}
                            />
                        </ReportDuration>
                    }
                </td>
            </tr>

            {hasSubIntervals && isOpened && subIntervals.map((row, index) =>
                <ReportSubInterval
                    key={`${index}-${row.start_time}`}
                    text={getSubIntervalComment(row.start_time)}
                    startTime={row.start_time}
                    endTime={row.end_time}
                    duration={row.duration}
                    onReportCommentChange={onSubIntervalCommentChange}
                    onTimeChange={onTimeChangeUniversal}
                />
            )}
        </React.Fragment>
    )
};

ReportInterval.propTypes = {
    taskStatus: PropTypes.number.isRequired
};

export default ReportInterval