import React from 'react';
import AddTask from '../../modules/tasks/containers/AddTask'
import TrackerTaskListContainer from '../../modules/tracker/containers/TrackerTaskListContainer'
import Filters from '../../modules/tasks/components/Filters'

import SidebarLayout from '../layouts/SidebarLayout'
import PageTitle from '../elements/PageTitle'

const TrackerPage = () => {
    return (
        <SidebarLayout>
            <PageTitle>Tracker</PageTitle>

           {/* <Filters />*/}
            <TrackerTaskListContainer />
        </SidebarLayout>
    );
};

export default TrackerPage;