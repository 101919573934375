import React from 'react';
import SidebarLayout from '../layouts/SidebarLayout'
import PageTitle from '../elements/PageTitle'

import CalendarCharts from '../../modules/reports/containers/CalendarCharts'
import CalendarChart from '../../modules/reports/components/CalendarChart/CalendarChart'
import {ALL_TASKS_STORE_KEY} from "../../modules/reports/constants";
import YMDDate from "../../utils/YMDDate";

const DashboardCharts = () => {
    return (
        <SidebarLayout>
            <PageTitle>Dashboard</PageTitle>
            <CalendarCharts>
                {/*<Test storeKey="all" year="2020" forceFetch={true} />*/}
                {/*<Test storeKey="pm" year="2019" forceFetch={true} />*/}
                <CalendarChart title="All Tasks" storeKey={ALL_TASKS_STORE_KEY} />

                <CalendarChart
                    title="Prepare Time Reports"
                    taskIds={['7e68b7e7-337f-4c9b-8088-771bba879c9a']}
                    storeKey="prepareReports"
                />
                <CalendarChart
                    title="Project Management"
                    taskTitleStartedWith="Project"
                    storeKey="pm"
                />
            </CalendarCharts>
        </SidebarLayout>
    );
};

export default DashboardCharts;