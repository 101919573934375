import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'reactstrap'

const File = ({ path, capturedAt }) => (
    <Col sm="3" style={{paddingBottom: '5px'}}>
        <img width="270" src={`data:image/jpeg;base64,${path}`} />
    </Col>
)

File.propTypes = {
    path: PropTypes.string.isRequired
}

export default File