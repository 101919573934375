import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';

const GroupControl = ({ grouped , onClick }) =>
    <FontAwesomeIcon
        icon={grouped ? faChevronCircleDown : faChevronCircleRight}
        style={{cursor: 'pointer'}}
        onClick={onClick}
    />

export default GroupControl;