import { connect } from 'react-redux'
import {changeStatus, fetchAggregatedTasksBegin} from '../actions/trackerTasks'
import TrackerTaskList from '../components/TrackerTaskList'
import TaskService from '../services/TaskService'

const mapStateToProps = state => ({
    trackerTasks: new TaskService(state.trackerTasks.items).filter(state.visibilityFilter),
    totalDuration: new TaskService(state.trackerTasks.items).calculateTotalDuration(),
    loading: state.tasks.loading,
    error: state.tasks.error
});

const mapDispatchToProps = dispatch => ({
    changeStatus: task => dispatch(changeStatus(task)),
    fetchTasks: (date) => dispatch(fetchAggregatedTasksBegin(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackerTaskList)