import React from 'react';
import TimeIntervalsContainer from '../../modules/intervals/containers/TimeIntervalsContainer'

import SidebarLayout from '../layouts/SidebarLayout'
import PageTitle from '../elements/PageTitle'

const IntervalsPage = () => {
    return (
        <SidebarLayout>
            <PageTitle>Detected Time Intervals</PageTitle>
            {/* <AddTask />*/}

           {/* <Filters />*/}
            <TimeIntervalsContainer />
        </SidebarLayout>
    );
};

export default IntervalsPage;