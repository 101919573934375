import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap';

const TrackerTask = ({ onClick, status, text, total_duration }) => (
    <tr className={
        'd-flex ' + (status ? 'table-warning' : '')
    }>
        <td className="col-7 col-lg-10 col-xl-10">{text}</td>
        <td className="col-3 col-lg-1 col-xl-1">
            <div style={{marginTop: "4px", textAlign: "center"}}>
                {total_duration.seconds > 0 && total_duration.hours_string}
            </div>
        </td>
        <td className="col-2 col-lg-1 col-xl-1">
            {
                status ?
                    <Button size="sm" color="danger" onClick={onClick}>Stop</Button>
                    : <Button size="sm" color="primary" onClick={onClick}>Start</Button>
            }
        </td>
    </tr>
)

TrackerTask.propTypes = {
    onClick: PropTypes.func.isRequired,
    status: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
}

export default TrackerTask